import axios from 'axios'

export default {
    state: {},
    actions: {
        async updateCafeActivity({commit}, data) {
            return new Promise(resolve => {
                axios.post(
                    'https://api.pizzanik.ru/cafe/web/update',
                    {
                        isActive: data.cafeActivity
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Token': data.jwtToken
                        }
                    })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        resolve(error.response.data)
                    })
            })
        }
    }
}