import axios from 'axios'

export default {
    state: {
        webAuthToken: '',
        jwtToken: '',
        credentials: {
            login: null,
            password: null
        },
        cafe: {
            address: '',
            isActive: false
        },
        result: false,
        errorMessage: ''
    },
    actions: {
        async webAuth({commit}, authData) {
            return new Promise(resolve => {
                axios.post('https://api.pizzanik.ru/operators/login_check',
                    {username: authData.login, password: authData.password})
                    .then((response) => {
                        if (response.data.result) {
                            commit('updateJwtToken', response.data.data.token)
                            commit('setAuthCredentials', authData)
                            commit('setCafe', {
                                address: response.data.data.cafe.address,
                                isActive: response.data.data.cafe.isActive
                            })
                        }

                        resolve(response.data)
                    })
                    .catch((error) => {
                        let err = error

                        if (error.response) {
                            err = error.response.data.error
                            commit('setError', err)

                        }

                        resolve({result: false, errorMessage: err})
                    })
            })
        },
        updateCafe({commit}, cafe){
            commit('setCafe', cafe)
        },
        logoutOperator({commit}) {
            commit('setCafe', {address: '', isActive: false})
            commit('setAuthCredentials', {login: null, password: null})
            commit('updateJwtToken', '')
        }
    },
    mutations: {
        setError(state, error) {
            state.errorMessage = error
            state.result = false
        },
        setAuthCredentials(state, credentials) {
            state.credentials = credentials
            localStorage.setItem('login', credentials.login)
            localStorage.setItem('password', credentials.password)
        },
        setCafe(state, cafe) {
            state.cafe = cafe
        },
        updateJwtToken(state, token) {
            localStorage.setItem('jwtToken', token)
            state.jwtToken = token
        }
    },
    getters: {
        getAuthCredentials(state) {
            const savedCredentials = {
                login: localStorage.getItem('login'),
                password: localStorage.getItem('password')
            }

            if (savedCredentials.login !== null && savedCredentials.password !== null) {
                state.credentials = savedCredentials
            }
            return state.credentials
        },
        getJwtToken(state) {
            state.jwtToken = localStorage.getItem('jwtToken')
            if (state.jwtToken === null) {
                state.jwtToken = ''
            }
            return state.jwtToken
        },
        getCafe(state) {
            return state.cafe
        },
        isLoggedIn(state, getters) {
            return getters.getJwtToken.length > 0
        }
    }
}