import Vue from 'vue'
import Vuex from 'vuex'
import operator from './modules/operator'
import queue from './modules/queue'
import menu from './modules/menu'
import cafe from './modules/cafe'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        cafe,
        operator,
        queue,
        menu
    }
})
