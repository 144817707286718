import axios from 'axios'

export default {
    state: {
        isMenuLoaded: false,
        menu: {}
    },
    actions: {
        async loadMenu({commit}) {
            return new Promise((resolve) => {
                axios.get('https://api.pizzanik.ru/menu/get-all')
                    .then(function (response) {
                        if (response.data.result) {
                            commit('setMenu', response.data.data)
                        }
                        resolve({
                            result: response.data.result,
                            errorMessage: response.data.error
                        })
                    })
                    .catch(function (error) {
                        commit('setError', error)
                        resolve({result: false, errorMessage: error})
                    })
            })
        }
    },
    mutations: {
        setMenu(state, menu) {
            // console.warn(menu)
            var menuItems = {}
            for (let menuItem of menu.menuItems) {
                menuItems[menuItem.id] = {
                    price: menuItem.price,
                    title: menuItem.title
                }
            }
            state.isMenuLoaded = true
            state.menu = menuItems
        },
        setError(state, error) {
            state.errorMessage = error
            state.result = false
        }
    },
    getters: {
        getIsMenuLoaded: state => {
            return state.isMenuLoaded
        },
        getMenuItem: state => itemId => {
            return state.menu[itemId].title
        }
    }
}