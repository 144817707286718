import axios from 'axios'

export default {
    state: {
        previousOrdersAmount: 0
    },
    actions: {
        async refreshQueue({commit}, jwtToken) {
            return new Promise((resolve) => {
                axios.post(
                    'https://api.pizzanik.ru/orders/web/queue/get',
                    {},
                    {
                        headers:
                            {
                                'Content-Type': 'application/json;charset=UTF-8',
                                'Token': jwtToken
                            }
                    })
                    .then(response => {
                        // commit('updatePreviousOrdersAmount', response.data.data.orders.length)
                        resolve(response.data)
                    })
                    .catch(error => {
                        resolve(error.response.data)
                    })
            })
        },
        async loadAllDailyQueue({commit}, jwtToken) {
            return new Promise((resolve => {
                axios.get(
                    'https://api.pizzanik.ru/orders/web/queue/get-daily',
                    {
                        headers:
                            {
                                'Content-Type': 'application/json;charset=UTF-8',
                                'Token': jwtToken
                            }
                    })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        resolve(error.response.data)
                    })
            }))
        },
        async updateOrderStatus({commit}, data) {
            return new Promise(resolve => {
                // console.warn('updateOrderStatus', data)
                axios.post(
                    'https://api.pizzanik.ru/orders/web/order/update',
                    data.order,
                    {
                        headers:
                            {
                                'Content-Type': 'application/json;charset=UTF-8',
                                'Token': data.jwtToken
                            }
                    })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        let err = error

                        if (error.response) {
                            err = error.response.data.error
                            commit('setError', err)

                        }

                        resolve({result: false, error: err})
                    })
            })
        }
    },
    /* mutations: {
         updatePreviousOrdersAmount: (state, amount) => {
             state.previousOrdersAmount = amount
         }
     },*/
    getters: {
        getPreviousOrdersAmount: state => {
            return state.previousOrdersAmount
        }
    }
}