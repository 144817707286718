<template>
    <div id="app">
        <router-view/>
        <v-snackbar
                v-model="showErrorSnackbar"
                :multi-line="true"
                absolute
                bottom>
            {{ errorMessage }}
            <template>
                <v-btn
                        color="red"
                        text
                        @click="showErrorSnackbar = false">
                    OK
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'App',
        data: () => ({
            showErrorSnackbar: false,
            errorMessage: ''
        }),
        components: {
            Loading
        },
        computed: mapGetters(["isLoggedIn"]),
        methods: mapActions(['loadMenu']),
        mounted() {
            if (!this.isLoggedIn) {
                this.$router.push('/login')
            }
        }
    }
</script>
